

const saveImgPreview = (_canvas) => {
    return new Promise((res,rej)=>{
        let img = new Image();
        img.onload = () => {
          var imageData = removeImageBlanks(img);
          res(imageData);
        };
        img.src = _canvas.toDataURL();
    });
  }


  const removeImageBlanks = (imageObject) => {
    let imgWidth = imageObject.width;
    let imgHeight = imageObject.height;
    var canvas = document.createElement('canvas');
    canvas.setAttribute("width", imgWidth);
    canvas.setAttribute("height", imgHeight);
    var context = canvas.getContext('2d');
    context.drawImage(imageObject, 0, 0);

    var imageData = context.getImageData(0, 0, imgWidth, imgHeight),
      data = imageData.data,
      getRBG = function (x, y) {
        var offset = imgWidth * y + x;
        return {
          red: data[offset * 4],
          green: data[offset * 4 + 1],
          blue: data[offset * 4 + 2],
          opacity: data[offset * 4 + 3]
        };
      },
      isTransparent = function (rgb) {
        return rgb.opacity === 0;
      },
      scanY = function (fromTop) {
        var offset = fromTop ? 1 : -1;

        for (var y = fromTop ? 0 : imgHeight - 1; fromTop ? (y < imgHeight) : (y > -1); y += offset) {
          for (var x = 0; x < imgWidth; x++) {
            var rgb = getRBG(x, y);
            if (!isTransparent(rgb)) {
              return fromTop ? y : Math.min(y + 1, imgHeight - 1);
            }
          }
        }
        return null;
      },
      scanX = function (fromLeft) {
        var offset = fromLeft ? 1 : -1;

        for (var x = fromLeft ? 0 : imgWidth - 1; fromLeft ? (x < imgWidth) : (x > -1); x += offset) {
          for (var y = 0; y < imgHeight; y++) {
            var rgb = getRBG(x, y);
            if (!isTransparent(rgb)) {
              return fromLeft ? x : Math.min(x + 1, imgWidth - 1);
            }
          }
        }
        return null;
      };

    var cropTop = scanY(true),
      cropBottom = scanY(false),
      cropLeft = scanX(true),
      cropRight = scanX(false),
      cropWidth = cropRight - cropLeft,
      cropHeight = cropBottom - cropTop;

    if (cropTop === null || cropBottom === null || cropLeft === null || cropRight === null) {
      return null; // Entire image is transparent, return null or handle this case
    }

    canvas.setAttribute("width", cropWidth);
    canvas.setAttribute("height", cropHeight);

    // Crop the image
    context.drawImage(imageObject,
      cropLeft, cropTop, cropWidth, cropHeight,
      0, 0, cropWidth, cropHeight);

    // Resize to max width of 200px while maintaining aspect ratio
    const maxWidth = 200;
    let newWidth = cropWidth;
    let newHeight = cropHeight;

    if (cropWidth > maxWidth) {
      // Calculate the new height to maintain the aspect ratio
      newWidth = maxWidth;
      newHeight = (cropHeight * maxWidth) / cropWidth;
    }

    // Create a new canvas for the resized image
    var resizedCanvas = document.createElement('canvas');
    resizedCanvas.setAttribute("width", newWidth);
    resizedCanvas.setAttribute("height", newHeight);
    var resizedContext = resizedCanvas.getContext('2d');

    // Draw the resized image
    resizedContext.drawImage(canvas, 0, 0, cropWidth, cropHeight, 0, 0, newWidth, newHeight);

    return resizedCanvas.toDataURL();
}
const getOpacity = color => {
  let opacity = 1;
  let c = color.toLowerCase();
  if(c.indexOf('crystal')>=0 || c === 'natural' || c === 'clear') {
    opacity = .7;
  }
  if(c.indexOf('glow')>=0) {
    opacity = 7.5
  }
  return opacity
}

const getColor = color => {
    let tmp;
    // console.log("getcolor",color)
    switch (color.toLowerCase()) {
      case 'peanut butter':
      case 'peanutbutter':
      case 'peanut butter v2':
        tmp = '#cd9141';
        break;
      case 'chocolate':
      case 'chocolate v2':
        tmp = '#7B3F00';
        break;
      case 'natural':
        tmp = '#f0ece9';
        break;
      case 'orange':
        tmp = '#cf4520';
        break;
      case 'purple':
        tmp = '#80225f';
        break;
      case 'purple crystal':
        tmp ='#790396';
        break;
      case 'white':
        tmp = '#f6f3f2';
        break;
      case 'mint':
        tmp = '#bbedde';
        break;
      case 'peach':
        tmp = '#FFDAB9';
        break;
      case 'sourapple':
      case 'sour apple v2':
        tmp = '#84bd00';
        break;
      case 'fuchsia':
        tmp = '#ff00ff';
        break;
      case 'nacho cheese':
        tmp = '#f6ad11';
        break;
      case 'midnight blue':
        tmp = '#191970';
        break;
      case 'jungle':
      case 'jungle green':
        tmp = '#364815';
        break;
      case 'glow stick green':
        tmp = '#27b900';
        break;
      case 'blueberry pearl':
        tmp = '#211371';
        break;
      case 'sea crystal':
        tmp = '#018c8f';
        break;
      case 'black cherry':
        tmp = '#590014';
        break;
      case 'dragonegg':
      case 'dragon egg':
        tmp = '#293828';
        break;
      case 'latte':
        tmp = '#d4c09c';
        break;
      case 'blue steel':
        tmp = '#54686f';
        break;
      case 'lavender':
      case 'lavender v2':
        tmp = '#debfdd';
        break;
      case 'charcoal':
      case 'charcoalgrey':
      case 'charcoal grey':
      case 'dark grey':
        tmp = '#323E42';
        break;
      case 'baja blue':
      case 'bajablue':
      case 'baja blue v2':
        tmp = '#008c95';
        break;
      case 'cottencandypink':
      case 'cottoncandypink':
      case 'bubble gum':
      case 'cotton candy pink':
        tmp = '#ff69b4';
        break;
      case 'cookies & cream':
      case 'cookies n\' cream':
        tmp = '#e8e8e8';
        break;
      case 'french vanilla':
        tmp ='#e9d9af';
        break;
      case 'blue steel':
        tmp ='#4682B4';
        break;
      case 'frost':
        tmp ='#afdcfa';
        break;
      case 'cosmic black': 
        tmp = '#38383a';
        break;
      case 'pirates gold':
        tmp ='#b29600';
        break;
      case 'clear':
        tmp = '#FFFFFF';
        break;
      case 'black':
      case 'mystery':
        tmp ='#333333';
        break;
      default:
        const tempElement = document.createElement('div');
            tempElement.style.color = color;
            document.body.appendChild(tempElement);

            // Get the computed color (rgb) and convert it to hex
            const computedColor = window.getComputedStyle(tempElement).color;
            document.body.removeChild(tempElement);

            // Convert RGB color to hex format
            tmp =  rgbToHex(computedColor);
    }
    return tmp;
  };

  const rgbToHex = (rgb) => {
    const result = rgb.match(/\d+/g);
    return `#${((1 << 24) + (parseInt(result[0]) << 16) + (parseInt(result[1]) << 8) + parseInt(result[2]))
        .toString(16)
        .slice(1)
        .toUpperCase()}`;
}


  export {saveImgPreview,removeImageBlanks,getColor,getOpacity};